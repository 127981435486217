import { interopDefault } from '~/router/utils'

export default [
  {
    path: '/information/support/',
    name: '__information_support',
    redirect: { name: '__information_contact' }
  },
  {
    path: '/information/contact/',
    name: '__information_contact',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/information/contact.vue' /* webpackChunkName: "pages-information-contact.vue" */
        )
      )
  }
]
