import { ValueOf } from 'type-fest'
import { Classified } from '~/models/classified/types'
import {
  Facet,
  TextSearchSearchableCategory,
  MassActions,
  MassActionsCustomSearchPage,
  SortField,
  MassActionsLockReason
} from '~/models/search/types'
import { GetterTreeWithRootState } from '~/store/types'
import { formatAdTargetingToKeyValue } from '~/services/search/formatters'
import { arraysEqual } from '~/utils/array'
import { SearchState, SHOW_SELLERS_PARAM } from './state'
import { ViewType } from '~/models/shared/types'
import { PaidClassifiedsInfoSummary } from '~/models/classified/paid/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import qs from 'qs'
import { SearchSettings } from '~/models/search/config'
import { CategoryId } from '~/models/category/types'

export default {
  inAllSearch(state) {
    return (
      state.categoryIds &&
      state.categoryIds.length === 1 &&
      state.categoryIds.includes(CategoryId.CLASSIFIEDS)
    )
  },
  showMakesLogos(state, getters) {
    return (
      state.categoryIds &&
      (getters.inCarsSearch ||
        getters.inBikesSearch ||
        state.categoryIds.includes(CategoryId.TAXIS) ||
        state.categoryIds.includes(CategoryId.VANS) ||
        state.categoryIds.includes(CategoryId.BUSES))
    )
  },
  inTaxisSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.TAXIS)
  },
  inBikesSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.BIKES)
  },
  inCarsSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.CARS)
  },
  inPartsSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.PARTS)
  },
  inBoatsSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.BOATS)
  },
  inXymaSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.XYMA)
  },
  inHobbySearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.HOBBY)
  },
  inRentalsSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.RENTALS)
  },
  inWantedPartsSearch(state) {
    return state.config.settings.searchName === 'wanted-parts'
  },
  inDealsSearch(state) {
    return (
      state.config &&
      state.config.settings &&
      state.config.settings.isDealsSearch
    )
  },
  inBlackFridaySearch(state) {
    return (
      state.config &&
      state.config.settings &&
      state.config.settings.isBlackFridaySearch
    )
  },
  inVehiclesSearch(state) {
    return (
      state.config &&
      state.config.settings &&
      state.config.settings.isVehiclesSearch
    )
  },
  isJobsSearch(state) {
    return state.config?.settings?.isJobsSearch
  },
  inPlotSearch(state) {
    return state.categoryIds && state.categoryIds.includes(CategoryId.PLOT)
  },
  getSearchCategoryIds(state) {
    return state.categoryIds
  },
  getAdsKeyValues(state) {
    return (state.adsTargetings || []).map(formatAdTargetingToKeyValue)
  },
  getAllRowsIds(state) {
    return (
      state.rows &&
      state.rows.map(row => {
        return row.id
      })
    )
  },
  getNumOfPages(state) {
    if (state.pagination) {
      const numOfPages = Math.ceil(
        state.pagination.total / state.pagination.perPage
      )
      if (numOfPages === 0) {
        return 1
      } else if (numOfPages % 1 === 0) {
        return numOfPages
      } else {
        return numOfPages + 1
      }
    }
    return 1
  },
  getTotalRows(state) {
    return state.pagination && state.pagination.total
  },
  getTotalCurrentPageRows(state) {
    return state.rows?.length
  },
  getResultsPaginationPage(state) {
    return (state.pagination && state.pagination.page) || 1
  },
  getQFacet(state) {
    if (state.facets) {
      return (
        state.facets.find(facet => facet.name === 'mein') ||
        state.facets.find(facet => facet.name === 'admin_mein') ||
        state.facets.find(facet => facet.name === 'long_mein')
      )
    }
    return null
  },
  getFacetByName(state) {
    return (name: string) => state.facets.find(facet => facet.name === name)
  },
  getSelectedFacets(state, getters) {
    return (state.facets || []).filter(handler => {
      return getters.facetIsSelected(handler)
    })
  },
  facetIsSelected() {
    return (handler: Facet) => {
      return handler.isSelected
    }
  },
  getCurrentCategoryId(state): number | null {
    if (state?.config?.rootParams?.category) {
      const { category } = state.config.rootParams
      if (Array.isArray(category)) {
        return category[0]
      }

      return category
    }

    return null
  },
  getCurrentCategory(state): Array<number> {
    if (!('category' in state.args)) {
      return []
    }

    return state.args.category
      .filter(
        (category: string) => category !== CategoryId.CLASSIFIEDS.toString()
      )
      .map(category => {
        return parseInt(category)
      })
  },

  inRootCategory(state, getters) {
    let currentCategory = [getters.getCurrentCategoryId]

    if (getters.getCurrentCategory?.length) {
      currentCategory = getters.getCurrentCategory
    }

    return (
      (state.config.rootParams &&
        state.config.rootParams.category &&
        arraysEqual(currentCategory, state.config.rootParams.category)) ||
      false
    )
  },
  getFacetsCount(_state, getters) {
    let len = 0
    const selectedFacets = getters.getSelectedFacets
    const categoryIsSelected = selectedFacets.some(
      (f: Facet) => f.name === 'category'
    )

    if (_state.isQuickSearch) {
      return categoryIsSelected
        ? selectedFacets.length - 1
        : selectedFacets.length
    } else if (selectedFacets && selectedFacets.length) {
      len = getters.getSelectedFacets.length

      if (categoryIsSelected && getters.inRootCategory) {
        len--
      }
    }

    return len
  },
  getRow(state) {
    return (id: number): Classified | undefined => {
      if (!state.rows) {
        return undefined
      }
      return state.rows.find(row => row.id === id)
    }
  },
  viewTypeIsGallery(state) {
    return state.viewType === ViewType.GALLERY
  },
  viewTypeIsList(state) {
    return state.viewType === ViewType.LIST
  },
  showSellers(state): boolean {
    return (
      !state.config?.settings?.disableShowSellers &&
      Boolean(state.params && state.params[SHOW_SELLERS_PARAM])
    )
  },
  getMassActions(state): MassActions | null {
    if (Object.keys(state.massActions).length > 0) {
      return state.massActions
    }
    return null
  },
  getRemainingMassActions(state): number {
    return state.massActions?.remainingActions || 0
  },
  getMaxAllowedActions(state): number {
    return state.massActions?.maxAllowedActions || 0
  },
  getMassActionsResetTimestamp(state): string {
    return state.massActions?.resetTimestamp || ''
  },
  getMassActionsCustomPages(state): MassActionsCustomSearchPage[] {
    return state.massActions?.customSearchPages?.pages || []
  },
  massActionsNeedSubscription(state): boolean {
    return Boolean(
      state.massActions?.locked &&
        state.massActions?.lockedReason === MassActionsLockReason.NEEDS_SUB
    )
  },
  showMassActions(state): boolean {
    return Boolean(state.massActions?.showMassActions)
  },
  getSortOptions(state): string {
    const { sort } = state.args as any
    return sort && sort[0]
  },
  getSetting: state => <T extends keyof SearchSettings>(
    key: T
  ): ValueOf<SearchSettings, T> => {
    return state.config.settings[key]
  },

  getPaidSummary(state): PaidClassifiedsInfoSummary | undefined {
    return state.paidSummary
  },
  getSelectedSort(state): SortField | null {
    return state.sortFields?.find(field => field.selected === true) || null
  },
  getTextSearchSearchableCategories(
    state,
    _,
    __,
    rootGetters
  ): TextSearchSearchableCategory[] {
    return (
      state.textSearch?.searchableCategories.filter(
        (c: TextSearchSearchableCategory) => {
          return rootGetters[`${USER_NS}/isAdmin`] || !c.adminOnly
        }
      ) || []
    )
  },
  getNotMyClassifiedsUrl(state): string | undefined {
    return state.notMyClassifiedsUrl
  },
  getSeoUrl(state): string | undefined {
    return state.page?.seoUrl
  },
  getTargetSeoUrl(state): string | undefined {
    return state.page?.targetSeoUrl
  },
  getLegacyAllFiltersPage(state) {
    return `${state.page?.externalUrl}`
  },
  useNewSearchbar(state, getters, _rootState): boolean {
    if (
      getters.inMyClassifieds ||
      getters.inDealsSearch ||
      getters.getSetting('isDealerSiteSearch') ||
      state.config?.settings?.isAdminSearch
    ) {
      return false
    }

    if (state.categoryIds) {
      const categoryIds = state.categoryIds
      const availableTextSearchCats = getters.getTextSearchSearchableCategories.map(
        (c: TextSearchSearchableCategory) => {
          return c.id
        }
      )

      return categoryIds.some(catId => availableTextSearchCats.includes(catId))
    }
    return false
  },
  inMyClassifieds(state): boolean {
    return Boolean(
      state.mySearchLinks?.length &&
        state.mySearchLinks.filter(m => m.selected).length
    )
  },
  shouldDisplayAds(state, getters): boolean {
    if (
      !state.config?.settings?.displayAds ||
      getters.userIsDealerAndOwnsSearch ||
      state.backendAdsConfig?.disabled
    ) {
      return false
    }
    return true
  },
  getCurrentRouteFullPath(state): string {
    const { path, query } = state.currentRoute
    return `${path}${qs.stringify(query, {
      addQueryPrefix: true,
      arrayFormat: 'repeat'
    })}`
  },
  userIsDealerAndOwnsSearch(state, _, __, rootGetters) {
    return state.userOwnsSearch && rootGetters[`${USER_NS}/isOfTypeDealer`]
  },
  hasLoadedFacets(state): boolean {
    return state.facets.length !== 0
  }
} as GetterTreeWithRootState<SearchState>
