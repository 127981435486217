export interface Option {
  text: string
  headerText?: string
  value: string | number | null
  count?: number
  disabled?: boolean
  isTop?: boolean
  label?: string
  selected?: boolean
  options?: Option[]
}

export interface BackendOption {
  name: string
  value: string | number
}

export interface IndexedOption {
  [key: string]: Option
}

export enum ViewType {
  LIST = 'list',
  GALLERY = 'gallery'
}

export enum I18nLocale {
  EL = 'el',
  EN = 'en',
  DE = 'de',
  BG = 'bg'
}

/**
 * @todo remove it completely in seperate PR
 */
export enum ConsentName {
  OVER18 = 'over18'
}

export interface SchemaFieldValue {
  name: string
  value: string | number
}

export interface SchemaFieldOption {
  type: string
  name: string
  label: string
  value: string
}

export interface SchemaField {
  name?: string
  type?: string
  disabled?: boolean
  required?: boolean
  min?: number | null
  max?: number | null
  values?: SchemaFieldValue[]
  countries?: CountryValidator[]
  social?: SocialValidator
  options?: SchemaFieldOption[]
  validators?: Schema
  notEmpty?: boolean | null
  notMissing?: boolean | null
}

export type StringMap<T> = Map<string, T>

export interface Postcode {
  country: Country
  postcode: {
    id: string | null
    name: string
  }
}

export interface Telephone {
  code: number
  dontShowToPublic?: boolean
  telephone: string
  social: Social
}

export interface Country {
  code: string
  id: number
  name: string
}

export interface Social {
  viber: boolean
  whatsapp: boolean
}

export interface AvailableLocale {
  locale: I18nLocale
  title: string
  icon: string
  link: string
}

export interface StaticFilesResult {
  staticUrl: string
  files: [StaticFile]
}

export interface StaticFile {
  path: string
  filename: string
}

export interface Step {
  disabled: boolean
  label: string
  selected: boolean
  vertical: boolean
  round: boolean
  completed: boolean
}

export type Schema = { [key: string]: SchemaField }
export type ArraySchema = Schema[]

export interface TelephoneValidator {
  code: string
  telephoneValidator: string
  name: string
  telephoneCode: number
}

export interface SocialValidator {
  social: Social
}

export interface CountryValidator {
  id: number
  code: string
  telephoneValidator: string
  name: string
  telephoneCode: number
}
