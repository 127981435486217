






import Vue, { PropType } from 'vue'
import { ErrorPageError } from '~/models/error'

export default Vue.extend({
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  }
})
