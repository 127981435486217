import { getAccountRoutes } from '~/router/routes/shared/account/common'
import { interopDefault } from '~/router/utils'

export default getAccountRoutes('/account', 'classifieds', [
  {
    path: 'api/',
    name: '__account_api',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/api.vue' /* webpackChunkName: "pages-shared-account-api.vue" */
        )
      ),
    meta: {
      scrollTopSmooth: true
    }
  }
])
