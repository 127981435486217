import { GetterTree } from 'vuex'
import { FormState } from './state'
import { Field, FuelTypeId } from '~/models/form/types'
import { arraysEqual } from '~/utils/array'
import { CategoryId } from '~/models/category/types'
import { getPlaceholderImage } from '~/utils/makemodel'
import { USER_NS } from '~/store/modules/shared/user/state'

export default {
  getFieldErrorByName(state) {
    return (name: string) => state.fieldErrors[name]
  },
  getFieldValueByName(state) {
    return (name: string) => {
      return state.fieldValues[name]
    }
  },
  getFieldIdByName(state) {
    return (name: string) => `${name}-${state.uniqueFormId}`
  },
  getAllFields(state) {
    return state.fieldsets.reduce((accumulator: any, fieldset) => {
      return [...accumulator, ...fieldset.fields]
    }, [])
  },
  fuelTypeIsElectric(state) {
    return arraysEqual(state.fieldValues?.fuel_type, [
      FuelTypeId.ELECTRIC.toString()
    ])
  },
  isCarsForm(state) {
    return state.categoryIds.includes(CategoryId.CARS)
  },
  isRentalCarsForm(state) {
    return state.categoryIds.includes(CategoryId.RENTALS_CARS)
  },
  isRentalsForm(state) {
    return state.categoryIds.includes(CategoryId.RENTALS)
  },
  isPlotForm(state) {
    return state.categoryIds.includes(CategoryId.PLOT)
  },
  isPartsForm(state) {
    return state.categoryIds.includes(CategoryId.PARTS)
  },
  isXymaForm(state) {
    return state.categoryIds.includes(CategoryId.XYMA)
  },
  isCarPartsForm(state) {
    return state.categoryIds.includes(CategoryId.PARTS_CAR)
  },
  isVehiclesForm(state, getters) {
    return (
      getters.isRentalsForm || state.categoryIds.includes(CategoryId.VEHICLES)
    )
  },
  isJobsForm(state) {
    return state.categoryIds.includes(CategoryId.JOBS)
  },
  isWantedPartsForm(state) {
    return state.categoryIds.includes(CategoryId.WANTED_PARTS)
  },
  isVehiclesXymaOrPartsForm(_state, getters) {
    return (
      (getters.isVehiclesForm && !getters.isRentalsForm) ||
      getters.isXymaForm ||
      getters.isPartsForm
    )
  },
  vehicleModelImage(state, getters) {
    const makeModelFieldSpecsModelImage = getters.getAllFields.find(
      (f: Field) => f.name === 'makemodel'
    )?.specs?.model_image

    return (
      // got a model image from specs
      makeModelFieldSpecsModelImage ||
      // no specs model image, try to get a placeholder instead
      getPlaceholderImage(state.categoryIds) ||
      null
    )
  },
  noModelsForThisMake(_state, getters) {
    const makemodelField = getters.getAllFields.find(
      (f: Field) => f.name === 'makemodel'
    )

    if (!makemodelField) {
      return false
    }

    const { specs } = makemodelField

    return (
      (specs.models && specs.models.length === 0) || specs.has_models === false
    )
  },
  onlyDealersAllowedGuard(state, _, __, rootGetters) {
    if (rootGetters[`${USER_NS}/isAdmin`]) {
      return false
    }

    return (
      state.extras?.onlyDealersAllowed &&
      !rootGetters[`${USER_NS}/isOfTypeDealer`]
    )
  },
  canUndo(state) {
    return (
      state.history.length &&
      state.historyActiveIndex > -1 &&
      state.historyActiveIndex <= state.history.length - 1
    )
  },
  canRedo(state) {
    return (
      state.history.length &&
      state.historyActiveIndex >= -1 &&
      state.historyActiveIndex < state.history.length - 1
    )
  },
  shouldRedirectUserToSaleRequestsPage(_, __, ___, rootGetters) {
    // Redirect all user types except for dealers
    return !rootGetters[`${USER_NS}/isOfTypeDealer`]
  },
  requiresTerms(state) {
    if (state.editMode) {
      return false
    }

    return (
      state.genericFormFields !== null &&
      state.genericFormFields.accepted_terms &&
      state.genericFormFields.accepted_terms.required
    )
  },
  hasPrice(state) {
    const months = state.fieldValues?.price?.months
    const price = state.fieldValues?.price?.price || state.fieldValues?.price

    const someMonthHasPrice =
      months && Object.values(months).some((m: any) => !!m.price)

    return price || someMonthHasPrice
  }
} as GetterTree<FormState, any>
