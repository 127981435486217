var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.internalValue)?_c('div',{ref:"modalContainerRef",staticClass:"tw-block tw-fixed tw-z-[2001]",attrs:{"data-modal":"true","tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleCloseOnEsc($event)}}},[_c('div',{staticClass:"tw-fixed tw-z-[2001] tw-left-0 tw-top-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-overflow-y-auto tw-min-w-0",class:[
      {
        'tw-transparent': !_vm.hideBackdrop && _vm.noFade,
        'tw-bg-grey-800/50': !_vm.hideBackdrop && _vm.noFade,
        'tw-items-center': _vm.centered,
        'tw-bg-grey-800/50 tw-transition-colors tw-duration-300':
          _vm.fadeIn && !_vm.hideBackdrop && !_vm.noFade
      },
      _vm.dialogClass
    ],on:{"click":_vm.handleClickBackdrop}},[_c('div',{staticClass:"tw-w-full tw-absolute tw-h-full tw-min-h-[100vh] !tw-min-h-[100dvh]",class:[
        _vm.sizeClass,
        {
          'tw-h-fit': !_vm.centered && !_vm.scrollable && !_vm.fullscreenMobile,
          'tw-flex tw-flex-col tw-justify-center tw-items-center': _vm.centered,
          'tw-h-max': _vm.centered && !_vm.fullscreenMobile,
          'tw-h-[100vh] !tw-h-[100dvh]': _vm.centered && _vm.fullscreenMobile,
          'tw-py-8 tw-top-0 ': !_vm.scrollable && !_vm.fullscreenMobile,
          'sm:tw-py-[50px] tw-top-0 ': !_vm.scrollable && _vm.fullscreenMobile,
          'tw-px-4': !_vm.fullscreenMobile,
          'sm:tw-px-4': _vm.fullscreenMobile
        },
        _vm.containerClass
      ]},[_c('div',{staticClass:"tw-bg-white  tw-w-full tw-shadow-md",class:[
          {
            'tw-border tw-border-solid tw-border-grey-300 tw-rounded':
              !_vm.iframeMode && !_vm.fullscreenMobile,
            'tw-h-full tw-max-h-[900px] tw-overflow-hidden sm:tw-rounded-2xl': _vm.iframeMode,
            'tw-h-[100vh] !tw-h-[100dvh] tw-rounded tw-flex tw-flex-col': _vm.fullscreenMobile,
            'sm:!tw-h-auto ': !_vm.iframeMode && _vm.fullscreenMobile,
            'tw-overflow-hidden': _vm.scrollable,
            'tw-opacity-0': !_vm.noFade,
            'tw-transition-opacity tw-opacity-100 tw-duration-300':
              _vm.fadeIn && !_vm.noFade
          },
          _vm.contentClass
        ],on:{"click":function($event){$event.stopPropagation();}}},[(!_vm.hideHeader && !_vm.iframeMode)?[_c('div',{staticClass:"tw-flex",class:_vm.headerContainerClass},[_c('div',{staticClass:"tw-w-full",class:[
                {
                  'tw-flex tw-items-center tw-justify-between': !_vm.hasCustomHeader,
                  'tw-px-4 tw-py-3': !_vm.iframeMode
                },
                _vm.headerClass
              ]},[(_vm.hasCustomHeader)?[_c('div',{staticClass:"tw-flex tw-justify-between"},[_vm._t("modal-header",null,{"close":_vm.handleCloseBtn})],2)]:[(_vm.hasCustomTitle)?[_vm._t("modal-title")]:_c('div',{staticClass:"tw-flex tw-items-center"},[(_vm.icon)?_c('CDynamicIcon',{staticClass:"tw-mr-2",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),_c('h5',{staticClass:"tw-mb-0 tw-text-xl tw-font-semibold tw-text-grey-800"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                  ")])],1)]],2),_vm._v(" "),(!_vm.hasCustomHeader && !_vm.hideHeaderClose)?_c('CButton',{staticClass:"!tw-bg-transparent !tw-border-0 !tw-text-grey-500 hover:!tw-text-grey-800 !tw-py-0 !tw-px-4 !tw-text-xl tw-flex tw-items-center modal-close-btn",class:_vm.closeIconClass,on:{"click":_vm.handleCloseBtn}},[_c('fa',{attrs:{"icon":_vm.icons.close}})],1):_vm._e()],1)]:_vm._e(),_vm._v(" "),_c('div',{ref:"body",staticClass:"tw-relative tw-min-h-10 tw-overflow-auto tw-p-4 tw-text-base modal-body",class:[
            {
              'fullscreen-mobile': _vm.fullscreenMobile,
              'scrollable-modal': _vm.scrollable,
              '!tw-p-0 tw-h-full': _vm.iframeMode
            },
            _vm.bodyClass
          ],attrs:{"tabindex":"-1"}},[(_vm.iframeMode)?_c('CButton',{staticClass:"!tw-bg-white/90 !tw-border-0 sm:!tw-text-grey-500 !tw-text-grey-800 hover:!tw-text-grey-800 !tw-absolute !tw-p-0 tw-right-2 tw-top-2 tw-w-[33px] tw-h-[33px] tw-flex tw-items-center tw-justify-center",on:{"click":_vm.handleCloseBtn}},[_c('CIcon',{staticClass:"tw-inline-flex tw-text-[15px]",attrs:{"icon":_vm.icons.iframeClose}})],1):_vm._e(),_vm._v(" "),_vm._t("default",null,{"ok":_vm.handleOk,"cancel":_vm.handleCancel,"close":_vm.handleCloseBtn})],2),_vm._v(" "),(!_vm.hideFooter)?[[_c('div',{staticClass:"tw-p-3 tw-flex tw-justify-end",class:[_vm.footerClass]},[_c('div',{staticClass:"child:tw-m-1 tw-w-full tw-flex tw-justify-end footer-container"},[_vm._t("modal-footer",null,{"ok":_vm.handleOk,"cancel":_vm.handleCancel})],2),_vm._v(" "),(!_vm.hasCustomFooter)?[(!_vm.okOnly)?_c('CButton',{staticClass:"tw-whitespace-nowrap",attrs:{"variant":_vm.cancelVariant,"outline":_vm.cancelOutline,"size":_vm.buttonSize,"disabled":_vm.cancelDisabled,"loading":_vm.loading},on:{"click":_vm.handleCancel}},[_vm._v("\n                  "+_vm._s(_vm.cancelTitleToShow)+"\n                ")]):_vm._e(),_vm._v(" "),_c('CButton',{staticClass:"tw-ml-3 tw-whitespace-nowrap",attrs:{"variant":_vm.okVariant,"outline":_vm.okOutline,"size":_vm.buttonSize,"disabled":_vm.okDisabled,"loading":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v("\n                  "+_vm._s(_vm.okTitle)+"\n                ")])]:_vm._e()],2)]]:_vm._e()],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }