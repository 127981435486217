










import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { BadgeSize } from '~/models/app/badge'

export default defineComponent({
  props: {
    bgClass: {
      type: String,
      default: null
    },
    textClass: {
      type: String,
      default: null
    },
    variant: {
      type: String as PropType<
        'primary' | 'light' | 'success' | 'danger' | 'warning' | 'info'
      >,
      default: 'info'
    },
    size: {
      type: String as PropType<BadgeSize>,
      default: 'md'
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const roundnessClasses = computed(() => {
      return props.rounded ? ['tw-rounded'] : ['tw-rounded-full']
    })

    const colorClasses = computed(() => {
      if (props.bgClass && props.textClass) {
        return [props.bgClass, props.textClass]
      }
      switch (props.variant) {
        case 'primary': {
          return ['tw-bg-primary-100', 'tw-text-primary-900']
        }
        case 'light': {
          return ['tw-bg-grey-300', 'tw-text-grey-800']
        }
        case 'success': {
          return ['tw-bg-green-100', 'tw-text-green-900']
        }
        case 'danger': {
          return ['tw-bg-red-100', 'tw-text-red-900']
        }
        case 'warning': {
          return ['tw-bg-yellow-100', 'tw-text-yellow-900']
        }
        case 'info':
        default: {
          return ['tw-bg-blue-100', 'tw-text-blue-800']
        }
      }
    })

    const sizeClasses = computed(() => {
      switch (props.size) {
        case 'lg': {
          return ['tw-px-3', 'tw-py-0.5', 'tw-text-base']
        }
        case 'sm': {
          return ['tw-py-0', 'tw-px-2', 'tw-text-sm']
        }
        case 'xs': {
          return ['tw-py-0', 'tw-px-1.5', 'tw-text-xs']
        }
        case 'md':
        default: {
          return ['tw-py-0.5', 'tw-px-2.5', 'tw-text-sm']
        }
      }
    })

    return { roundnessClasses, colorClasses, sizeClasses }
  }
})
