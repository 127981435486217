export interface UrlShortenerResult {
  popularityLink: string
  shortUrl: string
}

export interface UrlQueryParamSetter {
  (queryParams: Record<string, string | string[]>): void
}

export interface QueryParamSetterOptions {
  keyScope: Readonly<string[]>
  arrayParamHandling: ArrayParamHandling
}

export enum ArrayParamHandling {
  PARAM_PER_ELEMENT,
  COMMA_SEPARATED_VALUE
}
