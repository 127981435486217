

























import { Action } from './types'
import { getRouteMetaItem } from '~/utils/router'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SnackbarVariant } from '~/models/app/snackbar'

export default defineComponent({
  name: 'Snackbar',
  props: {
    variant: {
      type: String as PropType<SnackbarVariant>,
      required: false,
      default: null
    },
    action: {
      type: Object as PropType<Action>,
      required: false,
      default: null
    },
    classes: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      active: false,
      text: '',
      iframeStyle: null
    }
  },
  computed: {
    actionColor() {
      return this.action?.color || '#ffffff'
    },
    isCrossOrigin(): boolean {
      return getRouteMetaItem(this.$route, 'isCrossOrigin')
    }
  },
  methods: {
    onIframeScroll() {
      const offset = 50

      let bottom =
        window.parent.document.body.scrollHeight -
        window.parent.innerHeight -
        window.parent.document.documentElement.scrollTop

      bottom = bottom < offset ? 20 : bottom - offset

      this.iframeStyle = {
        bottom: bottom + 'px'
      }
    },
    close() {
      this.$emit('close')
    },
    show() {
      this.active = true
      if (!this.isCrossOrigin && window.parent && window.parent !== window) {
        window.parent.addEventListener('scroll', this.onIframeScroll, false)
      }
    },
    hide() {
      this.active = false
      if (!this.isCrossOrigin && window.parent && window.parent !== window) {
        window.parent.removeEventListener('scroll', this.onIframeScroll, false)
      }
    },
    actionClicked() {
      if (this.action.callback) {
        this.action.callback()
      } else {
        this.close()
      }
    }
  }
})
