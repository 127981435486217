/* eslint-disable camelcase */
import { ClassifiedBucketGroupCategory } from '~/models/category/types'
import { ClassifiedPhotos } from '~/models/classified/photo'

import { Postcode, Telephone, StringMap, ViewType } from '~/models/shared/types'
import { Location } from 'vue-router'
import {
  SearchArgRecord,
  Outlet,
  GoogleRating
} from '~/models/dealer/site/types'
import { UserType } from '~/models/user/types'
import { Geolocation } from '~/models/common/types'
import { DealerTelephone, SocialMediaLinks } from '~/models/dealer/types'
import { Description } from '~/models/classified/description/types'
import { ExternalCertificates } from '~/models/external-certificates/types'
import { Promotion } from '~/models/classified/promoted'
import { ClassifiedViewLeasing } from '~/models/classified/view/leasing'
import { ClassifiedViewFinance } from '~/models/classified/view/finance'
import { ClassifiedNote } from '~/models/classified/note'
import {
  ClassifiedExtraRentalService,
  ClassifiedRentalInsurance,
  ClassifiedRentalPickupLocation
} from '~/models/rentals/types'
import {
  ClassifiedCompactPhotosNative,
  ClassifiedCompactPhotosPanoramic
} from '~/models/photos/classified'
import { ShortVideo } from '~/models/short-video'
import { LoansInfo } from '~/constants/loan/loans'

export interface ClassifiedStates {
  is_non_public: boolean
  is_hidden: boolean
  is_removed: boolean
  is_deleted: boolean
  is_unlisted: boolean
  dealers_only: boolean
}
export interface ClassifiedPayment {
  value: any
  label: string
}

export interface ClassifiedSalary {
  value: string
  label: string
}

export interface ClassifiedAuditsInfo {
  available: boolean
  minimumPrice: number
  userHasAuditRequests: boolean
  requiresAcceptance: boolean
}

export interface Classified {
  /* eslint-disable camelcase */
  price_discounts: {
    days: number
    discount: number
  }[]
  own: boolean
  all_final_category_ids: string[]
  category: string
  is_parked: boolean
  states: ClassifiedStates
  vtype_xyma_or_part?: 'xyma' | 'part'
  category_ids: number[]
  consent?: {
    name: string
  }
  model_stub: string
  thumbs: {
    count: number
    urls: string[]
  }
  thumbs_patterns: {
    stretch: {
      list: boolean
      gallery: boolean
    }
    count: number
    urls: string[]
  }
  registration: string
  seo: {
    item_type: string
    item_prop: string
    partial_title: string
    complete_title: string
    name: string
  }
  notes?: ClassifiedNote | null
  label?: string
  dealer_cls: boolean
  id: number
  user_id: number
  user: {
    public_dealer?: boolean
    type: string // UserTypes
  }
  thumb: string
  title: string
  title_parts?: TitleParts
  external_link?: string
  short_description?: string
  mileage?: string
  engine_size?: string
  price: {
    value: string
  }
  extraRentalServices?: ClassifiedExtraRentalService[]
  rentalInsurance?: ClassifiedRentalInsurance
  rentalPickupLocation?: ClassifiedRentalPickupLocation
  paid_classified?: {}
  paidFeatures?: {
    isPaidOneClickAutoTouchEnabled: Boolean
    isPromotionNnabled: Boolean
  }
  discount?: ClassifiedDiscount
  address: string
  price_marked: string
  key_features: KeyFeature[]
  is_deleted: boolean
  seo_url: string
  seo_url_plot: string
  thumb_pattern: string
  offers_enabled: boolean
  created: string
  modified: string
  has_photos: boolean
  crashed?: boolean
  length?: number
  fueltype?: string
  address_long: string
  parked: string
  state_color: string
  offertype: string
  deleted_date?: string
  price_parked: string
  price_per_unit: PricePerUnit
  views: string
  clicks: string
  seller_telephone: {
    code: number
    dont_show_to_public: boolean
    telephone: string
  }
  damaged: boolean
  is_new: boolean
  condition: {
    is_new: boolean
  }
  finance: ClassifiedViewFinance
  leasing: ClassifiedViewLeasing
  installment: string
  description: Description
  payment?: ClassifiedPayment
  salary?: string
  videos?: ClassifiedVideo[]
  promotion?: Promotion
  from_promotion?: boolean
  geolocation?: Geolocation
  extras: Extra[]
  extra_specifications: [{ [key: string]: string }]
  external_certificates: ExternalCertificates
  photos?: ClassifiedPhotos
  compactPhotos: {
    panoramic: ClassifiedCompactPhotosPanoramic
    userPhotos: ClassifiedCompactPhotosNative
    knowledgebasePhotos: ClassifiedCompactPhotosNative
    native: ClassifiedCompactPhotosNative
    noPhoto: string
  }
  auto_touch: {
    classified_id: number
    expires: string
    id: number
    schedule: []
    touched_count: number
  } | null
  has_finance?: boolean
  has_leasing?: boolean
  has_certificate?: boolean
  pricePerMonth?: ClassifiedMonthPrice[]
  trades: {
    categories: []
    only_trades: boolean
  }
  showLoansInfo?: LoansInfo
  specifications: [{ [key: string]: string }]
  short_videos?: ShortVideo[]
  saleRequests?: ClassifiedSaleRequests
  descriptive_title?: string
  for_makemodels: ForMakeModel[]
  notifications: []
}

interface ForMakeModel {
  model: {
    id: number
    name: string
  }
  // eslint-disable-next-line camelcase
  year_from: number
  // eslint-disable-next-line camelcase
  year_to: number | null
  make: {
    id: number
    name: string
  }
}

export interface ClassifiedMonthPrice {
  price: string
  month: {
    name: string
    value: string
  }
}

export interface ClassifiedDiscount {
  from_price: number
  percentage: number
}

export interface ClassifiedVideo {
  url: string
  type: string
}

export interface Extra {
  name: string
  value: string
}

export interface KeyFeature {
  key: string
  value: string
  label: string
}

export interface Specification {
  suffix: string
  name: string
  value: string | number | object
  extra: object
  extra_info: string
  label: string
}

export interface PricePerUnit {
  unit: Unit
  value: number
  label: string
}

export enum Unit {
  LITTER = 'litter'
}

export interface User {
  displayName: string
  id: number
  postcode: Postcode
  telephone1: Telephone
  telephone2?: Telephone
  type: UserType
}

export interface DealerSiteConfigFactoryParams {
  searchArgs: SearchArgRecord
  defaultViewType: ViewType
  websiteId: string
  outlets: StringMap<Outlet>
}

export interface ClassifiedBucketByCategory {
  classifieds: Classified[]
  group: {
    category: ClassifiedBucketGroupCategory
    notificationsCount: number
    title: string
  }
}

export enum ClassifiedBucketType {
  FAVORITE = 'favorite',
  RECENT = 'recent'
}

export interface SellerVerification {
  telephoneVerified: boolean
  creditCardVerified: boolean
  canBeVerified: boolean
  userSince?: string
}

export interface Seller {
  avatar?: string | null
  website?: string | null
  geolocation?: Geolocation
  fax?: DealerTelephone | null
  mobile?: DealerTelephone | null
  telephone1: DealerTelephone | null
  telephone2: DealerTelephone | null
  extra_telephone: DealerTelephone | null
  name: string
  tags: any[]
  type: UserType
  only_contact_via_messages?: boolean | true
  address: string
  location: Location
  id: number
  userSince: number
  extraName?: string | null
  email: string
  managerId?: string | null
  analytics?: SellerAnalyticsConfig
  analytics_v4?: SellerAnalyticsConfig
  bl?: number // blacklist id
  npd?: boolean // non public dealer id
  show_more_classifieds_from_user?: boolean
  social_media?: SocialMediaLinks
  canReceiveMessages: boolean
  verification?: SellerVerification
  subscription_tier?: string | null
  agent_type?: string | null
  internal_page: string
  google_rating: GoogleRating
}

export interface SellerAnalyticsConfig {
  code: string
}

export enum OfferType {
  WANTED = 'wanted',
  RENT = 'rent',
  BOOKING = 'booking'
}

export interface ClassifiedCount {
  count: number
  seoUrl: string
}

export interface TitleParts {
  make: string
  variant: string
  model: string
  year: number
}

export interface ClassifiedDeletionMetaData {
  reviewScore: number
  wouldRecommendScore?: number
  suggestions?: string
  creationUxScore?: number
  featureSuggestions?: string
}

export interface ClassifiedSaleRequests {
  eligible?: boolean
  hasRequest: boolean
  automatic: boolean
  category: number
  damage: boolean
  fuelTypeId: string
  makeId: number
  mileageTo: number
  modelId: number
  registrationFrom: number
  registrationTo: number
  pendingSaleRequestId?: number
}
