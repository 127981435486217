







































import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'

export default defineComponent({
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    checked: {
      type: [String, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: '',
      required: false
    },
    labelClass: {
      type: String,
      default: '',
      required: false
    },
    variant: {
      type: String as PropType<'primary' | 'blue'>,
      default: 'primary'
    }
  },
  setup(props, { emit }) {
    const {
      id,
      checked,
      withBorder,
      large,
      disabled,
      inputClass,
      labelClass,
      value
    } = toRefs(props)

    const { createRandomId } = useId()
    const internalId = id.value ?? createRandomId()

    const internalValue = computed({
      get() {
        return checked.value
      },
      set(value) {
        emit('input', value)
        emit('change', value)
      }
    })

    const radioClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-h-6 tw-w-6')
      } else {
        classes.push('tw-h-4 tw-w-4')
      }

      if (disabled.value) {
        classes.push(
          'checked:tw-opacity-50 [&:not(:checked)]:tw-bg-grey-300 tw-border-grey-300'
        )
      } else {
        classes.push('tw-border-grey-400')

        if (props.variant === 'blue') {
          classes.push('tw-text-blue-500 group-hover:tw-border-blue-500')
        } else {
          classes.push('tw-text-primary group-hover:tw-border-primary')
        }
      }

      if (withBorder.value) {
        classes.push('tw-z-[1]')
      }

      return [inputClass.value, cursorClass.value, ...classes]
    })

    const cursorClass = computed(() => {
      return disabled.value ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
    })

    const roundnessClass = computed(() => {
      if (withBorder.value) {
        return large.value ? 'tw-rounded-xl' : 'tw-rounded-lg'
      }
      return ''
    })

    const borderClasses = computed(() => {
      const classes = []
      if (withBorder.value) {
        classes.push(
          'tw-relative tw-border tw-border-solid tw-border-grey-300 tw-p-3'
        )
        classes.push(roundnessClass.value)
        if (!disabled.value) {
          classes.push(
            props.variant === 'blue'
              ? 'hover:tw-border-blue-500'
              : 'hover:tw-border-primary'
          )

          if (checked.value === value.value) {
            classes.push(
              props.variant === 'blue'
                ? '!tw-border-blue-500'
                : '!tw-border-primary'
            )
          }
        }
      }
      return classes
    })

    const labelClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-text-lg')
      } else {
        classes.push('tw-text-base')
      }

      if (disabled.value) {
        classes.push('tw-text-grey-600')
      } else if (props.variant === 'blue') {
        classes.push(
          withBorder.value
            ? 'group-hover:tw-border-blue-500'
            : 'group-hover:tw-text-blue-500'
        )
      } else {
        classes.push(
          withBorder.value
            ? 'group-hover:tw-border-primary'
            : 'group-hover:tw-text-primary'
        )
      }

      if (withBorder.value) {
        classes.push('tw-z-[1]')
      }

      return [labelClass.value, cursorClass.value, ...classes]
    })

    return {
      internalId,
      internalValue,
      radioClasses,
      labelClasses,
      borderClasses,
      roundnessClass,
      cursorClass
    }
  }
})
