import { interopDefault } from '~/router/utils'

export const ClassifiedViewPage = () =>
  interopDefault(
    import(
      '~/pages/car/classifieds/view/view.vue' /* webpackChunkName: "pages-car-classifieds-view-view" */
    )
  )

export const classifiedSlugOrIdPattern = ':slugOrId(\\d+\\-*.*)'

export default [
  {
    name: '__classifieds_view_certificates',
    path: '/classifieds/:classifiedId/certificates',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/view/certificates.vue' /* webpackChunkName: "pages-car-classifieds-view-certificates" */
        )
      )
  },
  {
    name: '__classifieds_edit_eshop_iframe',
    path: '/classifieds/:classifiedId/eshop',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/edit/eshop.vue' /* webpackChunkName: "pages-car-classifieds-edit-eshop" */
        )
      )
  },
  {
    name: '__classifieds_view_view_certificates',
    path: '/classifieds/:classifiedId/view_certificates',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/view/view-certificates-iframe.vue' /* webpackChunkName: "pages-car-classifieds-view-view-certificates-iframe" */
        )
      )
  },
  {
    name: '__classifieds_view',
    path: `/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_plot',
    path: `/plot/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_plot_alt',
    path: `/plot/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_vtype',
    path: `/classifieds/:vtype/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_xyma',
    path: `/xyma/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_alt',
    path: `/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_alt2',
    path: `/classifieds/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_parts',
    path: `/parts/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_jobs',
    path: `/jobs/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_rentals',
    path: `/rentals/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  },
  {
    name: '__classifieds_view_wanted_parts',
    path: `/wanted-parts/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage,
    meta: {
      viewCls: true
    }
  }
]
