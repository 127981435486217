export const SET_GROUPS = 'SET_GROUPS'
export const SET_CLASSIFIEDS_LIST = 'SET_CLASSIFIEDS_LIST'
export const SET_SEARCHES = 'SET_SEARCHES'
export const SET_ERROR = 'SET_ERROR'
export const REMOVE_SEARCH = 'REMOVE_SEARCH'
export const ADD_TO_SEARCHES = 'ADD_TO_SEARCHES'
export const REMOVE_CLASSIFIED = 'REMOVE_CLASSIFIED'
export const ADD_FAVORITE_CLASSIFIED = 'ADD_FAVORITE_CLASSIFIED'
export const TOGGLE_RECENT_PARKED = 'TOGGLE_RECENT_PARKED'
export const SET_LOADING = 'SET_LOADING'
export const SET_RECENT = 'SET_RECENT'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_ACTIVE_RECENT = 'SET_ACTIVE_RECENT'
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'
export const CLEAR_COMPARE = 'CLEAR_COMPARE'
export const CLEAR_NOTIFICATION_FOR_INDEX = 'CLEAR_NOTIFICATION_FOR_INDEX'
export const CLEAR_NOTIFICATION_FOR_CLASSIFIED =
  'CLEAR_NOTIFICATION_FOR_CLASSIFIED'
export const SET_STYLE = 'SET_STYLE'
export const SET_CLASSIFIED_ORDER = 'SET_CLASSIFIED_ORDER'
export const SET_CLASSIFIEDS_WITH_NOTIFICATIONS =
  'SET_CLASSIFIEDS_WITH_NOTIFICATIONS'
export const SHOW_PARKING = 'SHOW_PARKING'
export const HIDE_PARKING = 'HIDE_PARKING'
export const FETCH_ALL_CLASSIFIED_LISTS = 'FETCH_ALL_CLASSIFIED_LISTS'
export const FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID =
  'FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID'
export const EDIT_CLASSIFIED_LIST = 'EDIT_CLASSIFIED_LIST'
export const REMOVE_CLASSIFIED_LIST = 'REMOVE_CLASSIFIED_LIST'
export const CREATE_CLASSIFIED_LIST = 'CREATE_CLASSIFIED_LIST'
export const SET_CLASSIFIED_LIST_LOADING = 'SET_CLASSIFIED_LIST_LOADING'
export const SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING =
  'SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING'
export const COPY_CLASSIFIED_LIST = 'COPY_CLASSIFIED_LIST'
export const DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST =
  'DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST'
export const REMOVE_LIST_FROM_FAVORITES = 'REMOVE_LIST_FROM_FAVORITES'
export const SET_PARKING_NOTIFICATIONS = 'SET_PARKING_NOTIFICATIONS'
export const CLEAR_PARKING_NOTIFICATIONS = 'CLEAR_PARKING_NOTIFICATIONS'
export const SET_DISPATCHED = 'SET_DISPATCHED'
export const SET_SWIPE_DIRECTION = 'SET_SWIPE_DIRECTION'
export const SET_NOTES_FOR_CLASSIFIED = 'SET_NOTES_FOR_CLASSIFIED'
