import { render, staticRenderFns } from "./CGoogleAutocompleteInput.vue?vue&type=template&id=70b99bf6&scoped=true&"
import script from "./CGoogleAutocompleteInput.vue?vue&type=script&lang=ts&"
export * from "./CGoogleAutocompleteInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b99bf6",
  null
  
)

export default component.exports